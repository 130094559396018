html {
  font-family: Roboto, Verdana, Geneva, Tahoma, sans-serif;
  scroll-behavior: smooth;
}

:root {
  --primaryColor: #ffcb06;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #ececec;
  --lightGrey: #cfcfcf;
  --darkGrey: #a3a3a3;
  --grey: #6b6b6b;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 3px;
  --lightShadow: 1px 1px 10px 10px rgba(231, 231, 231, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}

body {
  font-family: Roboto, Verdana, Geneva, Tahoma, sans-serif;
  overflow-x: hidden;
}

h1 {
  margin-bottom: 20px;
  font-family: Roboto, Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 700;
  font-size: 2rem;
  color: rgba(0, 0, 0, 0.87);
}

h2 {
  font-family: Roboto, Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  margin-top: 1.8px;
  color: rgba(0, 0, 0, 0.87);
}

h3 {
  font-family: Roboto, Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
  margin-top: 1.5px;
  color: rgba(0, 0, 0, 0.87);
}

p {
  font-family: Roboto, Verdana, Geneva, Tahoma, sans-serif;
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.67);
}

a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: #08679d;
}

.btn-primary {
  display: inline-block;
  color: var(--mainBlack);
  border: solid 1px #3b3b3b;
  border-radius: 25px;
  margin: 10px;
  padding: 5px 20px;
  background-color: var(--mainWhite);
  font-weight: 500;
  font-Size: 1rem;
  cursor: pointer;
}
.btn-primary:hover {
  background-color: var(--primaryColor);
  color: var(--mainBlack);
  border: none;
  text-decoration: none;
}

.btn-outlined-black {
  border: solid 1px #3b3b3b;
  color: var(--mainBlack);
  border-radius: 25px;
  padding: 5px 10px;
  max-width: 120px;
}
.btn-outlined-black:hover {
  border: solid 1px var(--primaryColor);
  background-color: var(--primaryColor);
  color: var(--mainBlack);
  border-radius: 25px;
  padding: 5px 10px;
  max-width: 120px;
}

img.img-scale {
  display: block;
  margin: auto;
  width: 100%;
  height: auto;
}

.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px
}
.nav-icon {
  margin-top: 1rem;
  margin-left: 1rem;
  max-width: 20vw;
  max-height: auto;
}
@media (max-width: 768px) {
  .nav-icon {
    margin-top: 1rem;
    margin-left: 20px;
    max-width: 25vw;
    max-height: auto;
  }
}
@media (max-width: 500px) {
  .nav-icon {
    margin-top: 1rem;
    margin-left: 0.1rem;
    max-width: 20vw;
    max-height: auto;
  }
}
.nav-link-container {
  text-align: center;
}
.nav-links {
  overflow: hidden;
  transition: var(--mainTransition);
  list-style-type: none;
  text-align: center;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
@media (max-width: 768px) {
  .nav-links {
    margin-top: 0.9rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
@media (max-width: 500px) {
  .nav-links {
    margin-top: 1rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }
}
.nav-links a {
  display: inline-flex;
  text-decoration: none;
  padding: 0.2rem 0.5rem;
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
}
@media (max-width: 768px) {
  .nav-links a {
    font-size: 1rem;
    font-weight: 500;
    padding: 10px, 10px;
  }
}
@media (max-width: 500px) {
  .nav-links a {
    font-size: 0.7rem;
    font-weight: 600;
    padding: 10px, 5px;
  }
}

#footer {
  width: 100%;
  background-color: #000;
  color: white;
}

#footer a {
  color: white;
}

#footer .spacer {
  height: 8px;
  background-color: #ffcb08;
}

#footer .links {
  padding: 20px 50px 5px;
  background-color: #000;
  line-height: 1.3em;
  text-align: center;
}

#footer .links .connect-circle {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  color: #000;
  display: inline-block;
  font-size: 1em;
  font-weight: bolder;
  margin-right: 5px;
  padding: 5px;
  text-align: center;
  width: 30px;
}

#footer .privacy {
  padding-bottom: 10px;
  font-size: 1em;
  text-align: center;
}

#footer .copyright {
  max-width: 800px;
  margin: 0 auto !important;
  float: none !important;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  #footer .copyright {
    margin: 0 5vw !important;
  }
}

.nav-tabs {
  margin-bottom: 3vh;
}

.text-wrap {
  white-space: normal !important;
}
